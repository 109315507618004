import React from "react"
import { parsePostContent } from "../../utils/blogUtils"
import Footer from "../landing/footer"
import Header from "../landing/header"
import PostFooter from "./postFooter"
import PostHeader from "./postHeader"
import PostsList from "./postList/postsList"

const BlogPost = ({ pageContext }) => {
  const { post, allPosts, lang } = pageContext

  const content = parsePostContent(post.content)

  return (
    <div className="card">
      {/* <BackToTopButton /> */}
      <div className="container post-container">
        <Header lang={lang}/>
        <PostHeader postData={post} lang={lang} />
        <div
          className="post-content"
          dangerouslySetInnerHTML={{
            __html: `
         ${content}
          `,
          }}
        ></div>
        <PostFooter postData={post} lang={lang}  />
      </div>
      <PostsList allPostsData={allPosts} lang={lang} />
      <Footer  lang={lang} />
    </div>
  )
}

export default BlogPost
