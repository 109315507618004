import React, { useState } from "react"
import { languages } from "../../const/languages"
import { t } from "../../utils/translationUtils";

const PostFooter = ({ postData, lang = languages.EN }) => {
  const [post, setPost] = React.useState()
  const [text, setText] = React.useState();

  React.useEffect(() => {
    setText(() => {return require(`../../translations/blog/${lang}.json`).cover})
  }, [])

  React.useEffect(() => {
    if (postData) {
      setPost(postData)
    }
  }, [postData])

  return (
    <div className="card post-footer">
      <hr style={{ marginBottom: "20px" }} />
      {post && (
        <div className="container">
          <div className="author-profile">
            <div>
              <img src={post.avatar.url} height={56} width={56} />
            </div>
            <div style={{ display: "grid" }}>
              <span className="subtitle-1 semibold">{post.author}</span>
              <span className="subtitle-2 light">{post.authorDescription}</span>
            </div>
          </div>
          <div className="item icons">
            <div
              style={{
                display: "grid",
                width: "76px",
                textAlign: "right",
                fontSize: "14px",
                lineHeight: "20px",
                alignItems: 'center'
              }}
            >
              {t(text.share)}
            </div>
            <div
              className="facebook"
              onClick={() => {
                window.open(
                  "https://www.facebook.com/people/AR-Labsio/100070021063417/"
                )
              }}
            ></div>
            <div
              className="linkedin"
              onClick={() => {
                window.open("https://www.linkedin.com/company/ar-labs-io")
              }}
            ></div>
            {/* <div className="youtube" onClick={() => {window.open("https://www.youtube.com/channel/UCkSXsJyPDBSotOS5TX-DMBA") }}></div>  */}
            <div
              className="instagram"
              onClick={() => {
                window.open("https://www.instagram.com/arlabs.io/")
              }}
            ></div>
          </div>
        </div>
      )}
      <hr style={{ marginTop: "20px" }} />
    </div>
  )
}

export default PostFooter
