import React from "react"
import Button from "../../../common/button/button"
import { decodeHtml, parseDate } from "../../../utils/blogUtils"
import { languages } from "../../../const/languages"
import { navigateLink } from "../../../common/navigateLink"
import { t } from "../../../utils/translationUtils"

const PostMiniature = ({ postData, lang = languages.EN }) => {
  const [post, setPost] = React.useState()
  const [text, setText] = React.useState();

  React.useEffect(() => {
    setText(() => {return require(`../../../translations/blog/${lang}.json`).cover})
  }, [])

  React.useEffect(() => {
    if (postData) {
      setPost(postData)
    }
  }, [postData])

  
  const readArticle = () => {
    navigateLink(`/blog/${post.slug}`, lang)
  }

  return (
    post && text && (
      <div className="post-miniature-contents onclick" id={post.id} onClick={readArticle}>
        <div style={{ maxHeight: "50px", height: "fitContent" }}>
          <div className="gradient post-category">{post.categories[0]}</div>
          <hr className="gradient" />
        </div>
        <div className="post-miniature-container">
          <img width={357} height={200} src={post.featuredImage} />
          <div>
            <div style={{ display: "grid", rowGap: "16px" }}>
              <h4 className="semibold" style={{ minHeight: "78px" }}>
                {post.title}
              </h4>
              <div className="body-2 light post-excerpt">
                {decodeHtml(post.excerpt)}
              </div>
            </div>
            <div className="flex-template-2-columns author-timestamp">
              <div className="grid">
                <div className="semibold">{parseDate(post.date, lang)}</div>
                <div className="body-2 light">{`${text.by}${post.author}`}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  )
}

export default PostMiniature
