import React from "react"
import Button from "../../../common/button/button"
import { parsePostData } from "../../../utils/blogUtils"
import PostMiniature from "./postMiniature"
import LatestPost from "./latestPost"
import { languages } from "../../../const/languages"
import { t } from "../../../utils/translationUtils"

const PostsList = ({ allPostsData, numberOfPosts, loadMore, lang = languages.EN }) => {
  const [postsData, setPostsData] = React.useState([])
  const [numOfPosts, setNumOfPosts] = React.useState(numberOfPosts)
  const [totalOfPosts, setTotalOfPosts] = React.useState(0)
  const [text, setText] = React.useState();

  React.useEffect(() => {
    setText(() => {return require(`../../../translations/blog/${lang}.json`).cover})
  }, [])

  React.useEffect(() => {
    if (allPostsData) {
      setPostsData(allPostsData.map(post => parsePostData(post)))
      setTotalOfPosts(allPostsData.length)
    }
    if (numberOfPosts) {
      setNumOfPosts(numberOfPosts)
    }
  }, [allPostsData])

  return (
    text &&
    <>
      <div className="card post-list-card">
        <div className="container" style={{ paddingTop: "0" }}>
          <div className="posts-gallery">
            {postsData &&
              postsData
                .slice(0, numOfPosts)
                .map(post => <PostMiniature id={post.id} postData={post} lang={lang} />)}
          </div>
          {loadMore && totalOfPosts > numOfPosts && (
            <div className="load-more-posts">
              <Button
                text={t(text.moreArticles)}
                className="button-dark"
                onClick={() => setNumOfPosts(numOfPosts + 3)}
              />
            </div>
          )}
        </div>
      </div>
    </>
  )
}

PostsList.defaultProps = {
  numberOfPosts: 3,
  loadMore: true,
}

export default PostsList
