import { languages, languagesTimestamp } from "../const/languages"

export const extractHtmlContents = htmlString =>
  htmlString.replace(/<{1}[^<>]{1,}>{1}/g, "")

export const decodeHtml = html => {
  const txt = document.createElement("textarea")
  txt.innerHTML = html
  return txt.value
}
export const timestampToDateString = (timestamp, options, lang) => {
  const date = new Date(timestamp);
  const newDate = date.toLocaleDateString(lang, options);
  return newDate;
}

export const slugFromString = str =>
  str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, "")
    .replace(/[\s_-]+/g, "-")
    .replace(/^-+|-+$/g, "")

export const parseDate = (dateString, lang = languages.EN) => {
  let result = '';
  switch (lang) {
    case  languages.EN: {
      const dateStr = timestampToDateString(dateString, { year: 'numeric', month: 'short', day: '2-digit' }, languagesTimestamp[lang]);
      const elems = (dateStr.replace(',', '')).split(" ")
      const date = ([elems[0], elems[1], elems[2]] = [elems[1], elems[0], elems[2]])
      result = date.join(" ");
      break;
    }
    case languages.PL: {
      result = timestampToDateString(dateString, { year: 'numeric', month: 'long', day: 'numeric' }, languagesTimestamp[lang]);
    }

  }
  return result;
}

export const parsePostData = data => {
  return {
    id: data.id,
    title: data.title || null,
    slug: slugFromString(data.title) || null,
    excerpt: extractHtmlContents(data.excerpt) || null,
    featuredImage: data.featuredImage ? (data.featuredImage.node.publicUrl || null) : null,
    categories: data.categories.nodes.map(category => category.name) || [],
    date: data.date || null,
    author: data.author.node.name || null,
  }
}

export const parsePostContent = content => content.replace("\n", "")
