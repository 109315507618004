import React from "react"
import { decodeHtml, parseDate } from "../../utils/blogUtils"
import { languages } from "../../const/languages"

const PostHeader = ({ postData, lang = languages.EN }) => {
  const [post, setPost] = React.useState()
  React.useEffect(() => {
    if (postData) {
      setPost(postData)
    }
  }, [postData])

  return (
    <div className="card">
      {post && (
        <div className="container post-header-container">
          <div className="post-content">
            <div className="gradient">{post.categories[0]}</div>
            <hr className="gradient" />
            <h2 className="semi-bold">{post.title}</h2>
            <h5 className="light">{decodeHtml(post.excerpt)}</h5>
            <div className="author-header">
              <div className="author-profile">
                <div>
                  <img src={post.avatar.url} height={56} width={56} />
                </div>
                <div style={{ display: "grid" }}>
                  <span
                    className="subtitle-1 semi-bold"
                    style={{ marginTop: "6px" }}
                  >
                    {post.author}
                  </span>
                  <span
                    className="subtitle-1 light"
                    style={{ marginBottom: "6px" }}
                  >
                    {post.authorDescription}
                  </span>
                </div>
              </div>
              <div className="post-timestamp semi-bold">
                {parseDate(post.date, lang)}
              </div>
            </div>
          </div>
          <img
            width={1000}
            src={post.featuredImage}
            className="post-header-image"
          />
        </div>
      )}
    </div>
  )
}

export default PostHeader
