import React from "react"
import Button from "../../../common/button/button"
import { decodeHtml, parseDate } from "../../../utils/blogUtils"
import { languages } from "../../../const/languages"
import { navigateLink } from "../../../common/navigateLink"
import { t } from "../../../utils/translationUtils"

const LatestPost = ({ postData, lang = languages.EN }) => {
  const [data, setData] = React.useState("")
  const [post, setPost] = React.useState()
  const [text, setText] = React.useState();

  React.useEffect(() => {
    setText(() => {return require(`../../../translations/blog/${lang}.json`).cover})
  }, [])

  React.useEffect(() => {
    setPost(postData)
  }, [postData])

  const readArticle = () => {
    navigateLink(`/blog/${post.slug}`, lang)
  }

  return (
    text &&
    <div className="card">
      <div className="container">
        {post && (
          <div
            onClick={readArticle}
            className="grid-template-2-columns latest-post onclick"
            style={{ columnGap: "64px" }}
          >
            <div>
              <div className="gradient">{post.categories[0]}</div>
              <h4 className="regular">{post.title}</h4>
              <div className="body-2 light" style={{ marginBottom: "40px" }}>
                {decodeHtml(post.excerpt)}
              </div>
              <div className="flex-template-2-columns author-timestamp">
                <div className="grid">
                  <div className="semibold">{parseDate(post.date, lang)}</div>
                  <div
                    className="body-2 light"
                    style={{ paddingBottom: "24xp" }}
                  >{`${text.by}${post.author}`}</div>
                </div>
              </div>
            </div>
            <img width={571} height={320} src={post.featuredImage} />
          </div>
        )}
      </div>
    </div>
  )
}

export default LatestPost
